//Created by Varsha
//Date: 27/06/2020

//for popup modal header
.modal-color {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: #ed6727 !important;
  color: white !important;
}

//for navbar color
.bg-mycolor {
  background-color: #ed6727 !important;
}

.color-mycolor {
  color: #ed6727 !important;
}

.btn-color {
  color: white !important;
  background-color: #183153 !important;
  border-color: #183153 !important;
}

//for tab color
.list-group-item.active {
  background-color: #183153 !important;
  border-color: #183153 !important;
}

.list-group .list-group-item-action:active {
  background-color: #183153 !important;
}

//for text color in navbar
.bg-mycolortext {
  color: #ed6727 !important;
}

//for bg color in dropdown navbar
.dropdown-notification .dropdown-menu-header {
  background-color: white !important;
}

.bg-white {
  background-color: white !important;
  color: #ed6727 !important;
}

.dropdown-notification .dropdown-menu.dropdown-menu-right::before {
  background: #f77930 !important;
  border-color: #f77930 !important;
}

.checkbox_design {
  background: no-repeat 50%/50% 50%;
  border: solid #ff0000 0.1px;
  border-radius: 3px;
  width: 1rem;
  height: 1.1rem;
  top: 0.1rem;
}

//for navbar avatar
.dropdown #navbarid::before {
  left: 9.5rem !important;
}

.crdnews-style {
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1) !important;
}

.ui-datatable {
  border: none;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

//for wrapping data in aggrid
.cell-wrap-text {
  white-space: normal !important;
  line-height: 15px !important;
  padding-top: 15px !important;
  // height: auto !important-;
}

// .rowHeightClass
// {
// height:100px !important;
// transform: translateY(100px);
// height:auto !important;
//background-color: pink !important;
// }

//for dropdown hover effect
.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #ff9f43 !important;
}

[dir] .header-navbar .navbar-container ul.nav li.dropdown .dropdown-menu {
  // margin-top: 46px;
}

.stikyStatusStyle {
  position: fixed;
  z-index: 9999;
  right: 0%;
}

.dz-img {
  display: block;
  width: 100%;
  height: 100%;
}

[dir] .rounded-circle {
  border-radius: 35% !important;
}

.my-class1 {
  width: 30% !important;
}

//for save button in popup
.btn-save-color {
  color: white !important;
  background-color: #183153 !important;
}

.button-green {
  // background-color:#dd27a6 !important;
  background-color: #0acb6e !important;
  font-size: 15px;
}

.button-green:hover {
  background-color: #3bee0e !important;
  font-size: 15px;
}

//for close button
.btn-close-color {
  color: white !important;
  background-color: #ea5455 !important;
}

//for add to open popup
.btn-listadd-color {
  color: white !important;
  background-color: #ed6727 !important;
}

.ul_status_style {
  border-right: unset !important;
  padding-right: unset !important;
  margin-right: unset !important;
}

.li_status_style {
  display: inline-block !important;
  margin-right: 3% !important;
  text-align: center !important;
  margin-bottom: unset !important;
}

.ml-style {
  margin-left: 11% !important;
}

.activity-timeline.timeline-right li.li_status_style .timeline-icon {
  position: unset !important;
  display: inline-block;
}

.activity-timeline.timeline-right li.li_status_style .timeline-info {
  vertical-align: middle;
  margin-left: 10px;
  display: inline-block;
}

//for export as CSV in aggrid
.btn-export-color {
  color: white !important;
  background-color: $warning !important;
}

//for reject button
.btn-reject-color {
  color: white !important;
  background-color: #183153 !important;
}

//for Blcok button
.btn-block-color {
  color: white !important;
  background-color: #183153 !important;
}

//for approve button
.btn-approve-color {
  color: white !important;
  background-color: #28c76f !important;
}

//for unblcok button
.btn-unblock-color {
  color: white !important;
  background-color: #28c76f !important;
}

.icon-plus-size {
  size: 20px;
}

.icon-div-plus-margin {
  margin-top: 25px;
}

//for plus icon
.fonticon-containerhover:hover svg {
  color: #ff9f43;
  transform: scale(1.4);
}

//for job posts
.box-jobview {
  min-height: 220px;
  overflow-y: auto;
}

.width-job-viewcolon {
  min-width: 30px;
}

.flex-container {
  display: -webkit-flex;
  display: flex;
}

.align-float-employee {
  float: right;
}

//row1
.box-employeeview-row1 {
  max-height: 200px;
  overflow-y: auto;
}

.box-employeeview-row2 {
  max-height: 260px;
  min-height: 260px;
  overflow-y: auto;
}

.box-employeeview-row3 {
  min-height: 260px;
  max-height: 260px;
  overflow-y: auto;
}

.img-size {
  width: 450px;
  height: 215px;
  display: block !important;
  margin-right: auto;
  margin-left: auto;
}

.icon_style {
  margin-right: 10px;
}

.icon_style_mr {
  margin-right: 25px;
}

//for aggrid width as percentage
.ag-header-container {
  width: 100% !important;
  min-width: 100% !important;
}

.ag-header-row {
  width: 100% !important;
  min-width: 100% !important;
}

.ag-center-cols-container {
  width: 100% !important;
  min-width: 100% !important;
}

.ag-header {
  width: 100% !important;
  min-width: 100% !important;
}

.ag-body-viewport {
  min-width: 100% !important;
}

//for back button in job details
.btn_style {
  margin-bottom: 3rem !important;
  margin-top: 30px !important;
}

//for job details
.card-margin {
  margin-bottom: 0px !important;
}

.load_btn_style {
  color: #7367f0 !important;
  text-decoration: underline !important;
  padding-left: 15px !important;
}

.box-employeeview-row4 {
  height: 250px;
}

.box-chat-box {
  height: 355px;
}

.box-jobview-approve {
  height: 100px;
}

.norecord_height {
  height: 100px;
}

.norecord_pad {
  padding: 20px;
}

.employee-page-view-table .user-info-width .user-info-title {
  min-width: 200px !important;
}

.employee-page .user-info div {
  padding: 0rem !important;
}

.employee-page-view-table .user-info-pd div {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0rem;
  padding-bottom: 0.1rem;
}

.employee-page {
  padding-top: 15px !important;
}

.employee-page-md {
  padding-top: 20px !important;
}

.cls-left {
  margin-left: -48px !important;
}

.class-left {
  margin-left: 15px !important;
}

.classs-left {
  margin-left: 0px !important;
  margin-right: 10px !important;
}

.class-right {
  margin-right: 10px !important;
}

.cls-eye {
  margin-left: -30px;
  margin-top: 33px;
}

.employee-page-lg {
  padding-top: 25px !important;
}

.photo {
  height: 200px;
}

.photo-Carousel {
  height: 198px !important;
  width: 416px !important;
  display: block;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  align-content: center;
}

.image_btn_style {
  position: absolute;
  left: 60px;
  /* top: 35px; */

  top: 43px;
  border: unset;
  background-color: unset !important;
  padding: unset;
}

.image_resume_style {
  position: absolute;
  left: 65px;
  top: 45px;
  border: unset;
  background-color: unset !important;
  padding: unset;
}

.image_logo_style {
  //opacity: 0.3;
  position: absolute;
  left: 26px;
  top: 45px;
  border: unset;
  background-color: unset !important;
  padding: unset;
}

.hide_image_style:hover {
  opacity: 0.5 !important;
}

.hover_image_style:hover {
  opacity: 0.5 !important;
}

.image_btn_style i {
  color: #000000;
  z-index: 1053;
}

.upload_image_style {
  vertical-align: middle;
  text-align: center;
  color: #f8f8f8 !important;
  padding: 15px !important;
}

.list-col-style {
  display: inline-block;
  padding: 0;
}

.thumb_image_bg {
  background: #babfc7 !important;
  border-radius: 15px !important;
}

//for status badge margin
.icon-style-margin {
  margin-right: 5px;
}

.bg-mypurple {
  background-color: #ba48ba;
}

.bg-myprimary {
  background-color: #7367f0;
}

.bg-mydanger {
  background-color: #ea5455;
}

.bg-orange {
  background-color: #ff9f43;
}

.bg-purple {
  background-color: #7d0260;
}

.bg-pink {
  background-color: #f26f86;
}

.bg-mysuccess {
  background-color: #28c76f;
}

.bg-mywarning {
  background-color: #ff9f43;
}

.bg-mywarning1 {
  background-color: #fff2ccff;
}
.bg-mywarning2 {
  background-color: #e2f0d9;
}

//for bg color in card icon
.bg-rgba-primary {
  background: #7367f0 !important;
}

.bg-rgba-success {
  background: #28c76f !important;
}

.bg-rgba-warning {
  background: #ff9f43 !important;
}

.bg-rgba-danger {
  background: #ea5455 !important;
}

.bg-rgba-pink {
  background: rgb(252, 166, 181) !important;
}

.bg-rgba-mycolor {
  background: #ed6727 !important;
}

.bg-rgba-white {
  background: white !important;
}

//for language count icon` color
.color-mysucess {
  color: #28c76f;
}

.color-mydanger {
  color: #ea5455;
}

.color-mywarning {
  color: #ff9f43;
}

.color-myprimary {
  color: #7367f0;
}

.color-mypink {
  color: rgb(255, 121, 143) !important;
}

.color-myblack {
  color: black !important;
}

.color-mywhite {
  color: white !important;
}

.color-mysuccess {
  color: #28c76f;
}

.list-col-style1 {
  padding: 0px;
}

.nav-itemnav-linkactive {
  background-color: #fff;
}

.list_dropdownwidth_employee {
  width: 150px;
}

//employer management edit
.color-edit {
  color: #7367f0 !important;
}

.color-save {
  color: #28c76f !important;
}

.link-tab .nav-link.active:after {
  background: linear-gradient(
    30deg,
    #28c76f,
    rgba(32, 235, 117, 0.5)
  ) !important;
}

.link-tab .nav.nav-tabs .nav-item .nav-link {
  font-size: 0.95rem;
  min-width: auto;
  font-weight: 450;
  color: #000 !important;
}

* {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  max-width: 600px;
}

.tags-input {
  display: inline-block;
  margin: 0 auto;
  padding: 5px;
  width: 393px;
  height: 100px;
  font-size: 14px;
  border: #cecece solid 1px;
  border-radius: 3px;
}

.tag {
  height: 20px !important;
  margin: 5px;
  padding: 5px;
  display: inline-flex;
  padding: 2px 5px;
  background-color: #cecece;
  border-radius: 3px;
}

.input-class {
  width: 90px;
  height: 20px !important;
  border: #cecece solid 1px;
  outline: none;
}

button.delete {
  display: inline-block;
  margin-left: 5px;
  padding: 0;
  width: 15px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 10px;
  font-weight: bold;
  // cursor: pointer;
  border-radius: 50px;
  border: none;
  outline: none;
  user-select: none;

  &::after {
    content: "X";
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
  }
}

.nav.nav-tabs .nav-item .nav-link.active {
  color: #ed6727 !important;
}

.nav.nav-tabs .nav-item .nav-link.active:after {
  left: 0;
  background: linear-gradient(
    30deg,
    #ed6727,
    rgba(226, 126, 40, 0.5)
  ) !important;
}

.modal-footersubscr {
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
}

.card-width {
  min-width: 100% !important;
}

.main-menu .navbar-header {
  padding-right: 1rem !important;
  padding-left: 0rem !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.mr {
  display: block !important;
  margin: auto !important;
}

//for job package
.icon-divjob-plus-margin {
  margin-top: 7px;
}

//for li tags to be in horizontal
.horizon {
  float: left;
  padding-right: 20px;
}

.pr-padding {
  padding-right: 20px;
}

.pt-padding {
  padding: 3px !important;
}

//employer management list
.color-close {
  color: #ea5455 !important;
}

.card .box-shadow-employer {
  -webkit-box-shadow: 1px 1px 1px 2px #ccc !important;
  -moz-box-shadow: 1px 1px 1px 2px #ccc !important;
  box-shadow: 1px 1px 2px 2px #ccc !important;
}

.card .box-shadow-employer {
  border: 1px solid #cccccc;
}

//for multi select
.multiSelectHt {
  height: 240px !important;
}

//for formfeedback msg
.mydisplay {
  display: block !important;
  color: grey !important;
}

.col-width-5 {
  width: 5% !important;
}

.col-width-9 {
  width: 9% !important;
}

.col-padding {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.col-width-10 {
  width: 10% !important;
}

.col-width-12 {
  width: 12% !important;
}

.col-width-15 {
  width: 14% !important;
}

.col-width-20 {
  width: 20% !important;
}

.col-width-25 {
  width: 25% !important;
}

.col-width-30 {
  width: 30% !important;
}

.col-width-35 {
  width: 35% !important;
}

.col-width-40 {
  width: 40% !important;
}

.col-width-45 {
  width: 45% !important;
}

.col-width-50 {
  width: 50% !important;
}

.col-width-55 {
  width: 55% !important;
}

.col-width-60 {
  width: 60% !important;
}

.col-width-65 {
  width: 65% !important;
}

.col-left-0 {
  left: 0% !important;
}

.col-width-3 {
  width: 3% !important;
}

.col-width-5 {
  width: 5% !important;
}

.col-left-7 {
  left: 7% !important;
}

.col-left-8 {
  left: 8% !important;
}

.col-left-10 {
  left: 10% !important;
}

.col-left-15 {
  left: 15% !important;
}

.col-left-20 {
  left: 20% !important;
}

.col-left-22 {
  left: 22% !important;
}

.col-left-23 {
  left: 23% !important;
}

.col-left-24 {
  left: 24% !important;
}

.col-left-25 {
  left: 25% !important;
}

.col-left-30 {
  left: 30% !important;
}

.col-left-33 {
  left: 33% !important;
}

.col-left-35 {
  left: 35% !important;
}

.col-left-36 {
  left: 36% !important;
}

.col-left-38 {
  left: 38% !important;
}

.col-left-40 {
  left: 40% !important;
}

.col-left-42 {
  left: 42% !important;
}

.gnjob-editor-flag {
  margin: 0em;
}

.public-DraftStyleDefault-block {
  margin: 0em;
}

.html-text-desc {
  text-align: start;
}

.html-text-desc-text {
  color: #ed6727 !important;
}

.col-left-43 {
  left: 43% !important;
}

.col-left-45 {
  left: 45% !important;
}

.col-left-47 {
  left: 47% !important;
}

.col-left-50 {
  left: 50% !important;
}

.col-left-53 {
  left: 53% !important;
}

.col-left-55 {
  left: 55% !important;
}

.col-left-57 {
  left: 57% !important;
}

.col-left-59 {
  left: 59% !important;
}

.col-left-60 {
  left: 60% !important;
}

.col-left-63 {
  left: 63% !important;
}

.col-left-65 {
  left: 65% !important;
}

.col-left-67 {
  left: 67% !important;
}

.col-left-68 {
  left: 68% !important;
}

.col-left-70 {
  left: 70% !important;
}

.col-left-73 {
  left: 73% !important;
}

.col-left-75 {
  left: 75% !important;
}

.col-left-80 {
  left: 80% !important;
}

.col-left-83 {
  left: 83% !important;
}

.col-left-84 {
  left: 84% !important;
}

.col-left-85 {
  left: 85% !important;
}

.col-left-92 {
  left: 92% !important;
}

.col-left-90 {
  left: 90% !important;
}

.col-left-92 {
  left: 92% !important;
}

.col-left-93 {
  left: 93% !important;
}

.text-align-span {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.col-left-95 {
  left: 95% !important;
}

//aggrid header text color
.ag-header-cell-label .ag-header-cell-text {
  color: black !important;
}

.ml-style-px {
  margin-left: 40px;
}

.image_btn_stylee {
  position: absolute;
  left: 50px;

  top: 53px;
  border: solid;
  background-color: rgb(108, 108, 114) !important;
  // color:white !important;
  // padding: unset;
}

hr {
  margin-top: 0rem !important;
  margin-bottom: 1rem !important;
  border: 0px solid #060606 !important;
  /* border-top: 1px solid rgba(34, 41, 47, 0.1); */
}

.thumb_image_bgg {
  background: #babfc7 !important;
  border-radius: 15px !important;
}

.upload_image_sstyle {
  vertical-align: middle;
  text-align: center;
  color: #f8f8f8 !important;
  padding: 20px !important;
  border-radius: 45px !important;
}

.image_icon_style {
  position: absolute;
  left: 60px;
  /* top: 35px; */
  top: 43px;
  border: unset;
  background-color: unset !important;
  padding: 15px !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.border-profile.dz-thumb {
  border-radius: 50px !important;
  border: 3px solid darkgrey;
  margin-bottom: 8px;
  padding: 4px;
}

label.stylebranch {
  font-weight: bold;
  font-size: 14px;
  padding-left: 20px !important;
}

label.styleaddress {
  padding-left: 20px !important;
}

.thumb_image_bgprofile {
  background: #babfc7 !important;
  border-radius: 47px !important;
}

//for text color in navbar
.bg-subscriptioncolor {
  color: darkblue !important;
}

.form-group.subscription_align_post {
  padding-left: 55px;
}

.subscription_align_expiry.form-group {
  float: right;
}

.bgcolor_plan.row {
  background-color: #1c1c77;
}

hr.subscription-hr {
  margin-top: -1rem !important;
  margin-bottom: 1rem !important;
  border: 0px solid #060606 !important;
}

.btn-location-color {
  color: white !important;
  background-color: #183153 !important;
  padding: 0.3rem 1rem !important;
}

label.aadhar_label,
.fs-12 {
  font-size: 12px !important;
}

.txt-cnt {
  text-align: center;
}

.fs-11 {
  font-size: 11px !important;
}

.mrg-45 {
  margin-right: 4.5% !important;
}

.btn-download-color {
  color: white !important;
  background-color: #183153 !important;
  padding: 0.5rem 1rem !important;
}

.btn-back-color {
  color: white !important;
  background-color: #ed6727 !important;
  width: 100px !important;
  padding: 0.5rem 1rem !important;
}

.btn-back-color_delete {
  color: white !important;
  background-color: #dc1111 !important;
  width: 100px !important;
  padding: 0.5rem 1rem !important;
}

.btn-revoke-color {
  color: white !important;
  background-color: #183153 !important;
  width: 100px !important;
  padding: 0.5rem 1rem !important;
}

.height-aggrid {
  height: 105px !important;
}

.btn-add-color {
  color: white !important;
  background-color: #ed6727 !important;
  width: 65px !important;
  height: 20px !important;
  padding: 0 !important;
}

.btn-filter-color {
  color: white !important;
  background-color: #ed6727 !important;

  padding: 0.4rem 1rem !important;
}

.btn-view-color {
  color: white !important;
  background-color: #0e4d96 !important;
}

.pad-07 {
  padding: 0.7rem 0.7rem !important;
}

@media (min-width: 768px) {
  .modal_style_splash {
    max-width: 60% !important;
  }
}

.dz-thumb.img-width_splash.thumb_image_bg {
  height: 400px !important;
}

label.splash_image {
  padding-top: 160px !important;
  padding-left: 75px !important;
}

.splashimg {
  display: block;
  height: 100%;
  width: 100%;
}

.divimagesplash {
  height: 100% !important;
}

//for checkbox color
.vx-checkbox-con input:checked ~ .vx-checkbox .vx-checkbox--check {
  background-color: #183153 !important;
}

.vx-checkbox-con input:checked ~ .vx-checkbox {
  border-color: #183153 !important;
}

.display {
  display: inline-block !important;
}

.text-align {
  text-align: center;
}

//government jobs
.image-padding {
  padding-bottom: 10px !important;
}

[dir]
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::before {
  // box-shadow: none;
  background-color: #183153 !important;
}

//logout color
.btn-submitalert-color {
  color: white !important;
  background-color: #183153 !important;
}

// * {
//   font-family: sans-serif;
//   font-size: 17px;
// }

// body {
//   background: #f2f2f2;
//   padding: 20px;
// }

.input-tag {
  background: white;
  //border: 1px solid #d6d6d6;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
}

.input-tag input {
  border: none;

  // width: 100%;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  background: #ff840d;
  border-radius: 6px;
  color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 5px 10px;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  background: #ffffff;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 2px 5px 3px 6px;
  transform: rotate(45deg);
  width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  //flex-grow: 1;
  //padding: 0;
}

.border-colr {
  outline: none !important;
}

.remove-btn:focus {
  outline: none !important;
}

.update-icon {
  color: #28c76f !important;
}

.clear-icon {
  margin-left: 8px !important;
  color: #dc1111 !important;
}

.icon-space {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

//print
#printme {
  display: none;
}

@media print {
  #printme {
    display: block;
  }
}

.thumb_image_bgprofile_list {
  background: #a9a9a9 !important;
  border-radius: 35px !important;
}

.card .box-shadow-employerview {
  border-bottom: 2px solid #eee !important;
  border-radius: 0.1px !important;
  margin-bottom: 0.2rem !important;
}

.employee-pageview {
  padding-top: 10px !important;
}

.form-control-position-newpassword {
  position: absolute;
  top: 18px;
  z-index: 1;
  display: block;
  width: 3rem;
  height: 2.5rem;
  line-height: 2.5rem;
  right: 0;
}

.smstemp_active_margin {
  margin-top: 7%;
}

//send sms
.browsie_temp {
  margin-bottom: 11px !important;

  word-wrap: break-word !important;
}

.wrapee {
  word-wrap: break-word !important;
  font-size: 11px !important;
  padding: 6px !important;
}

.cardactiveee {
  padding: 6px !important;
}

.temname_color {
  color: #fff !important;
}

.temname_bgcolr {
  background-color: #8a8888 !important;
  margin-bottom: 5% !important;
}

.specialization_height {
  max-height: 200px !important;
  overflow: auto !important;
}

.skill_mapping_height {
  margin-top: 10px !important;
  max-height: 200px !important;
  overflow: auto !important;
}

[dir] .flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #f6f7f9 !important;
}

[dir] .flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #f6f7f9 !important;
}

.reference_scrol {
  max-height: 246px !important;
  overflow-y: scroll !important;
  display: block;
}

.skill_scrol {
  height: 120px !important;
  overflow-y: scroll !important;
  display: block !important;
}

.experience_scrol {
  max-height: 242px !important;
  overflow-y: scroll !important;
  display: block !important;
}

.col-pad {
  padding-left: 0px !important;
}

.education_scrol {
  max-height: 216px !important;
  overflow-y: scroll !important;
  display: block !important;
}

.dropzone {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

[dir] .dropzone {
  border: 2px dashed #7367f0;
  background: #f8f8f8;
}

.dropzone:focus {
  outline: 0;
}

.dz-thumb {
  display: inline-flex;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
}

[dir] .dz-thumb {
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  padding: 4px;
}

[dir="ltr"] .dz-thumb {
  margin-right: 8px;
}

[dir="rtl"] .dz-thumb {
  margin-left: 8px;
}

.thumb-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

[dir] .thumb-container {
  margin-top: 16px;
}

.dz-thumb-inner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.cls-size {
  display: block;
  width: 63px;
  height: 63px;
}

.cls-img {
  display: inline-flex;
  width: 0;
  height: 0;
  box-sizing: border-box;
}

.employee-header {
  padding: 1.5rem 1.5rem 1.5rem 1.5rem !important;
}

.header {
  padding-left: 10px;
}

.employ_border {
  border-bottom: 2px solid #eee;
  padding-bottom: 10px !important;
  padding-top: 10px;
}

.eclipse-hid {
  white-space: nowrap;
  width: 289px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  position: relative !important;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute !important;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible !important;
}

.eyeicon-border {
  margin-bottom: 0;
  text-align: center;
  background-color: #ededed;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  margin-right: 1px;
  z-index: auto;
}

.newpasswordeyeicon {
  margin-bottom: 0 !important;
  text-align: center;
  background-color: #ededed;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1px !important;
  margin-right: 15px !important;
}

//login
.logineyeicon {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  background-color: #ededed !important;
  border-radius: 1px !important;
  margin-left: 1px !important;
  height: 2.6em;
}

.margintopu {
  margin-top: 7px;
  white-space: break-spaces !important;
  flex-grow: 3;
}

[dir] p {
  /* margin-top: 0; */
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.text_box_design {
  padding: 0.7rem 0.7rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #5f5f5f;
  display: block;
  border: 1px solid #d9d9d9;
  width: 100%;
  height: calc(1.25 * 1em + 1.4rem + 1px);
  font-size: 0.96rem;
  font-weight: 400;
  line-height: 1.25;
  color: #4e5154;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.text_box_design:focus {
  border: 1px solid #7367f0;
  outline: #7772c2;
}

[dir] input {
  margin: 0;
}

.focus_button:focus {
  background-color: #204c92 !important;
}

.focus_button1:focus {
  background-color: #92a1cc8a !important;
  outline: none;
  box-shadow: none;
}

.errincorrectpwd {
  color: red;
  padding-top: 10px !important;
  margin-left: 23% !important;
}

.errincorrectpwd_new {
  color: red;
  padding-top: 20px;
  padding-left: 25px;
  text-align: center;
}

[dir] .btn {
  font-size: 0.99rem !important;
}

.flexendfilter {
  justify-content: flex-end;
}

.menu-item {
  font-size: 0.99rem !important;
}

[dir] label {
  font-size: 0.97rem !important;
}

.form-control {
  font-size: 0.99rem !important;
}

body {
  font-size: 0.99rem !important;
}

[dir="ltr"] .icon_style {
  margin-right: 7px !important;
}

// [dir] .cursor-pointer {
//   cursor: pointer;
//   padding:3px !important;
// }
.employee-page-view .user-info div {
  padding: 0rem !important;
}

.cls-star {
  margin-left: 80%;
  position: absolute;
  top: 10%;
}

.align_style {
  display: inline-block;
  min-width: 90px;
}

.div_style {
  display: inline-block;
  min-width: 20px;
  text-align: center;
}

.cls-shadow {
  border: 1px solid #ccc;
}

.cls-style {
  position: absolute;
  margin-left: 14%;
  bottom: 12px;
  font-size: 14px;
  margin-bottom: -7%;
}

.cls-style_cls {
  position: absolute;
  margin-left: 20%;
  bottom: 12px;
  font-size: 14px;
  margin-bottom: -3%;
}

.chnage_btn {
  text-align: right;
}

.align-left_center {
  text-align: justify;
  padding: 10px;
  margin-top: 10px;
}

// .HWHoi .flexooo_desc{
//   flex-grow: 3;
// }
.cardheading1dstyle {
  font-size: 18px;
  text-align: left;
}

.card-header.cardactive {
  color: white !important;
  background-color: #dd27a6 !important;
  padding: 20px 0px 10px 0px !important;
  border-bottom: 1px solid #cac2c2 !important;
}

.plan-color {
  color: #000 !important;
  font-size: 11px !important;
}

.cardheading2dstyle {
  font-size: 18px;
  text-align: right;
}

.card2rowsforsubscribe {
  padding-bottom: 40px;
}

.cardrowactive {
  border-bottom: 1px solid #cac2c2;
  margin-bottom: 10px;
  height: 30px;
}

.cls-commonstyle {
  font-size: 12px;
  font-weight: bold;
}

.ademployee-belowfield {
  opacity: 0.5;
}

.pref-locat-setting {
  margin-top: 22px;
  padding-left: 35px;
  font-size: 10px;
}

.card .paddingunset {
  padding-bottom: unset !important;
}

.bag-plan {
  padding: 1px 5px 4px 5px;
  background-color: #fff !important;
}

.badge-news {
  position: absolute !important;
  top: 4px !important;
  left: 28px !important;
  border-radius: 80% !important;
  padding: 4px 7px 3px 6px !important;
}

.badge-new {
  position: absolute !important;
  top: 4px !important;
  left: 39px !important;
  border-radius: 80% !important;
  padding: 4px 7px 3px 6px !important;
}

.badge-events {
  position: absolute !important;
  top: 4px !important;
  left: 48px !important;
  border-radius: 80% !important;
  padding: 4px 7px 3px 6px !important;
}

.bg-mycolor-news {
  background-color: #ed6727 !important;
}

.btn-invite-color {
  color: white !important;
  background-color: #28c76f !important;
  padding: 0.3rem 1rem !important;
}

.fa-lg-location {
  padding-left: 3px;
  font-size: 20px;
}

.fa-lg-envelope-call {
  font-size: 17px;
}

.envelope-margin {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .skill-mapping-modal {
    min-width: 62% !important;
    max-width: 62% !important;
  }
}

.skill-alpha-uncheck {
  padding: 2px;
  padding-top: 2px;
  font-size: 10px !important;
  max-width: 13px;
  margin-top: 10px !important;
  min-width: 20px;
  margin-left: 10px;
  vertical-align: middle !important;
  color: rgb(3, 2, 2) !important;
  font-weight: bold;
  border-width: 1.5px;
  background-color: #fbfbfb !important;
  border-color: #183153 !important;
  text-align: center;
}

.skill-alpha {
  padding: 5px;
  font-size: 10px !important;
  max-width: 30px;
  margin-top: 10px !important;
  min-width: 20px;
  margin-left: 10px;
  vertical-align: middle !important;
  color: white !important;
  background-color: #183153 !important;
  text-align: center;
}

.cls-quali .css-t2iux5-DropDown {
  z-index: 9999 !important;
}

// .css-1uccc91-singleValue{
//   z-index:9999 !important;
// }
.seeker-setting {
  padding-left: 20px;
  margin-top: 36px !important;
  font-weight: bold;
}

.employ-setting {
  padding-left: 20px;
  margin-top: -12px !important;
  font-weight: bold;
}

.seting_txtbo {
  margin-top: 13px;
}

.pref-locat-setting1 {
  margin-top: 0px !important;
  padding-left: 35px;
  font-size: 10px;
}

.margin-righ {
  padding-right: 123px !important;
}

.employee-padleft {
  padding-right: 233px !important;
}

.cls-msg {
  padding: 16%;
  font-size: 277%;
  text-align: center;
  font-family: "Archivo", sans-serif !important;
}

// .blank-page .content-wrapper .flexbox-container {
//   background-color: white;
// }
.opacity_approve {
  color: #b6e0c9 !important;
}

.css-2b097c-container .css-2613qy-menu {
  position: absolute;
  box-sizing: border-box;
  z-index: 9999 !important;
}

.special_check {
  position: relative !important;
}

.cls-align {
  position: relative;
  height: 174px;
}

.cls-divvalue {
  position: absolute;
  top: 35px;
  //z-index: 9999 !important;
}

.cls-value {
  position: absolute;
  top: 80%;
}

.cls-ali {
  min-width: 184px !important;
  padding-left: 116px;
}

.cls-btno {
  margin-left: 123px;
}

.info_icon {
  width: 80px !important;
  height: 80px !important;
}

.info_text {
  color: #c7c7c7;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
}

[dir] .card-body .cardbody_filter_pad {
  padding: 0px !important;
}

[dir] .card-body1 {
  padding: 0rem;
}

.cls-wrap {
  max-width: 51ch;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.eclipse-hidde {
  overflow: hidden;
  max-width: 42ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notify-row {
  padding: 10px;
}

.notify-margin {
  margin-top: 10px;
}

.notify-border {
  border-bottom: 1px solid #eeeeee;
}

.notify-border:hover {
  box-shadow: 5px 8px 5px #eeeeee;
}

.notify-time {
  text-align: end;
  margin-top: 21px !important;
}

.notify-badge {
  text-align: end;
  margin-top: 11px !important;
}

.notify-back {
  background-color: #f77930 !important;
  padding: 10px;
  text-align: center;
}

.notify-title {
  color: #ffffff !important;
}

.image_btn_style1 {
  background: unset;
  border: unset;
  padding: unset;
  opacity: 0.5;
  color: #000000;
  float: right;
}

.btton {
  right: 3px !important;
  position: absolute;
  left: 0 !important;
}

.norecord-info {
  font-style: normal;
  color: #000000 !important;
  text-align: center;
  margin-top: 230px;
}

.norecord-info-all {
  font-style: normal;
  color: #000000 !important;
  text-align: center;
  margin-top: 150px;
  margin-bottom: 150px;
}

.dismiss-all {
  text-align: right !important;
  color: #fff !important;
  font-size: 13px;
}

.dismiss-all:hover {
  text-decoration: underline;
}

.noti_tab_color {
  color: black !important;
}

.noti-full-page-card {
  padding: 1.5rem 1.5rem 1.5rem 1.5rem !important;
}

.box-shadow-employerview .nav.nav-tabs {
  background-color: #f77930 !important;
}

.notify-scrol .nav.nav-tabs {
  background-color: #f77930 !important;
}

.nav-item .active > .col-md-3 {
  background-color: #f77930 !important;
}

.nav.nav-tabs .nav-item .nav-link {
  color: #000000 !important;
  border-right: 1px solid #fff !important;
  border-top: 1px solid #fff !important;
  border-bottom: 1px solid #fff !important;
}

.box-shadow-employerview .nav-tabs .col-md-4 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.notify-scrol .nav-tabs .col-md-3 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.notify-scrol .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.notify-scrol .row .nav-item {
  text-align: center !important;
}

.cls-notifullpage .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.cls-notifullpage .row .nav-item {
  text-align: center !important;
}

//  [dir=ltr]  .nav.nav-tabs {
//   background-color: #ed6727 !important;
//  }
.notify-scrol {
  width: 362px;
  min-height: 500px;
  max-height: 500px;
  overflow: auto;
  background-color: #ffffff;
  margin: auto;
  position: relative;
  overflow-x: hidden;
  // color: #000 !important;
}

.customCircularProgress {
  color: #000 !important;
  background: #f77930;
  border-radius: 50%;
  padding: 6px;
}

.header-navbar[class*="bg-"] .navbar-nav .nav-item a col {
  color: #fff !important;
}

.dropdown-user .user-name,
.cls-icon {
  color: #fff !important;
}

//  .header-navbar .navbar-container ul.navbar-nav li
//  {

//  }
.notify-scrol li {
  line-height: 1.3 !important;
  text-align: center;
}

a.nav-link {
  color: #ffff !important;
}

.dismiss-all-class {
  text-align: end;
}

.dismiss-all-class:hover {
  text-decoration: underline;
}

.opacity_noti_msg {
  background-color: #eeeeee !important;
}

.float-right {
  float: right;
}

//cursor handover
// [dir] img{
// cursor:pointer !important;
// }
// [dir] .badge {
//   //background-color: #28c76f;
//   //cursor: pointer !important;
// }
// .fa {
// cursor:pointer !important;
// }
[dir] .card .card {
  cursor: pointer !important;
}

.chartjs-render-monitor {
  // animation: chartjs-render-animation 0.001s;
  cursor: pointer !important;
}

._loading_overlay_overlay {
  background: #0000000a !important;
  opacity: 0.6 !important;
  color: black !important;
  position: fixed !important;
}

.span-cls {
  border-radius: 7px;
}

.cls-load {
  margin-left: 109% !important;
  align-self: center !important;
  margin-top: 186% !important;
}

.cls-remove {
  top: 0 !important;
  right: 0 !important;
  position: absolute !important;
}

.cls-btn_align {
  padding: 0 !important;
  border: none !important;
  background: none !important;
}

.cls-p_style {
  color: brown !important;
  font-size: 124% !important;
  margin-left: 18px !important;
}

// .dropdowncheckbox
// {
//   // position:'relative' !important;

// }
.window.scroll {
  top: 0 !important;
  left: 0 !important;
  behavior: "smooth" !important;
}

.select__menu {
  position: "absolute" !important;
  z-index: 9999 !important;
}

.cls-news {
  top: 12px !important;
}

.cls-col {
  padding: 0 !important;
}

.bg-mg4 {
  margin-left: -4px !important;
}

.skill-badge-div {
  max-width: 30ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  // overflow: hidden;
}

.select__menu .dropdown {
  position: "absolute" !important;
  z-index: 9999 !important;
}

.label_cls {
  font-weight: bold !important;
  margin-left: 33% !important;
  font-size: 1.5rem !important;
}

.welcome_image {
  margin-left: 23% !important;
  margin-top: 4% !important;
  align-items: center !important;
}

.welcome_text {
  margin-left: 31% !important;
  font-size: 19px !important;
  font-weight: bold !important;
}

.cls-facility {
  overflow: hidden !important;
  padding: 0px !important;
  margin-left: -31px !important;
}

.spc-wrap {
  max-width: 29ch;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.district-wrap {
  max-width: 29ch;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gnorganization-wrap {
  max-width: 29ch;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.city_district-wrap {
  max-width: 22ch;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.skill_jobrole-wrap {
  max-width: 22ch;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.jobrole-wrap {
  max-width: 29ch;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news_events-wrap {
  max-width: 18ch;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

//chat-bubble
.speech-bubble {
  position: relative;
  background: #f0f0f0;
  border-radius: 0.4em;
  padding: 30px;
  // margin: 5px;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 65%;
  width: 0;
  height: 0;
  border: 13px solid transparent;
  border-right-color: #f0f0f0;
  border-left: 0;
  border-bottom: 0;
  margin-top: -5.5px;
  margin-left: -13px;
}

.speech-bubbles {
  position: relative;
  background: #f0a675;
  border-radius: 0.4em;
  // margin: 5px;
}

.speech-bubbles:after {
  content: "";
  position: absolute;
  right: 0;
  top: 65%;
  width: 0;
  height: 0;
  border: 13px solid transparent;
  border-left-color: #f0a675;
  border-right: 0;
  border-bottom: 0;
  margin-top: -5.5px;
  margin-right: -13px;
}

.progress .progress-bar {
  background-color: #ed6727;
  text-align: right;
}

.regviaapp-portal {
  font-size: 35px;
}

.widget-align {
  display: flex;
  border: 1px solid orange;
  border-radius: 4px;
  padding: 5px;
  background: #f8f3f3;

  p,
  span {
    padding: 12px;
    background: white;
  }

  p,
  span {
    border-right: 1px solid orange;
  }
}

.div-just {
  justify-content: center;
  display: flex;
}

.disp-flex {
  display: flex;
}

.align-item {
  align-items: center;
}

.fw-bold {
  font-weight: bold;
}