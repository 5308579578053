// placeholder spacific scss
$custom-file-padding-y: 0;
$custom-file-padding-x: 0;
.form-control {
  &::placeholder {
    font-size: 0.85rem;
    color: rgba($black, 0.4);
    transition: all 0.2s ease;
  }

  &:-ms-input-placeholder {
    color: darken($body-bg, 20%);
  }

  &:focus {
    &::placeholder {
      transform: translate(5px);
      transition: all 0.2s ease;
    }
    ~ .form-control-position {
      svg {
        stroke: $primary;
      }
    }
  }
}

/* Form specific Scss */

// Form Label Scscc
label {
  color: darken($body-bg, 70%);
  font-size: 0.85rem;
  margin-bottom: 0;
  padding-left: 0.2rem;
}

// Text area fontsize and line height
textarea.form-control {
  line-height: 1.6rem;
  font-size: 1rem;
}

// Form group Scscc
.form-group {
  margin-bottom: 1.5rem;
  .valid-tooltip,
  .invalid-tooltip {
    top: auto;
  }
}

// Form control Scscc
.form-control {
  border: 1px solid darken($body-bg, 12%);
  color: darken($body-bg, 60%);
}
// Used for Card - Check it and remove if not used
.form-section {
  color: $gray-600;
  line-height: 3rem;
  margin-bottom: 20px;
  border-bottom: 1px solid $gray-600;
}

/* .form-body{
    overflow: hidden;
} */

// Used for Card - Check it and remove if not used
.form-actions {
  border-top: 1px solid darken($body-bg, 10%);
  padding: 20px 0;
  margin-top: 20px;

  &.filled {
    background-color: $body-bg;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  &.top {
    border-top: 0;
    border-bottom: 1px solid darken($body-bg, 10%);
    margin-top: 0;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    .buttons-group {
      float: left !important;
      margin-bottom: 10px;
    }
  }
}

// Form-horizotnal layout
.form-horizontal {
  .form-group {
    @include media-breakpoint-up(sm) {
      .label-control {
        text-align: right;
      }
    }
  }
}

// Form Control Large
.form-control.form-control-lg {
  padding: 1rem;
  &::placeholder {
    font-size: 1.25rem;
  }
}

// Form Control Small
.form-control.form-control-sm {
  padding: 0.6rem;
  &::placeholder {
    font-size: 0.7rem;
  }
}

// Custom-File-Input
.custom-file-label {
  padding: ($custom-file-padding-y - 0.3) $custom-file-padding-x;
  &::after {
    padding: ($custom-file-padding-y - 0.3) $custom-file-padding-x;
    height: auto;
  }
}

// Select Inputs Specific SCSS
select.form-control {
  &:not([multiple="multiple"]) {
    // background-image:
    //   url("../../app-assets/images/pages/arrow-down.png");
    background-position: calc(100% - 12px) 13px, calc(100% - 20px) 13px, 100% 0;
    background-size: 12px 12px, 10px 10px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 1.5rem;
  }
}

// For customs-select remove double arrow && padding
.custom-select {
  padding: 0.7rem 1.7rem 0.6rem 0.7rem;
  -moz-appearance: none; // For firefox
  -webkit-appearance: none; // for chorme
}

// input group addon
.input-group-addon {
  background-color: #eceff1;
  border-color: #babfc7;
}

// Custom Checkbox
.custom-control-label::before {
  width: 1.1rem;
  height: 1.1rem;
  top: 0.1rem;
}
.custom-control-label::after {
  background: no-repeat 50%/50% 50%;
  border: solid #4e4c4c 0.1px;
  border-radius: 3px;
  width: 1.1rem;
  height: 1.1rem;
  top: 0.1rem;
}

// Custom-checkbox when not disabled and active
.custom-control-input:not(:disabled) {
  &:active ~ .custom-control-label {
    &::before {
      background-color: $primary;
      border-color: $primary;
    }
  }
}

//form-tags-input
// .tagging-js-tags, .tagging-methods{
//     .form-control{
//         height: auto;
//     }
// }

/* Floating label Group */
.form-label-group {
  position: relative;
  margin-bottom: 1.5rem;

  // Form-Control-Large in Floating Label Group
  .form-control-lg {
    ~ label {
      font-size: 1.25rem;
      padding: 1rem;
    }
  }

  // Form-Control-Small in Floating Label Group
  .form-control-sm {
    ~ label {
      font-size: 0.7rem;
    }
  }

  // Form-label
  > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    transition: all 0.25s ease-in-out;
    padding: 0.6rem;
    color: rgba($black, 0.4);
    font-size: 0.85rem;
    opacity: 0;
    z-index: -1;
  }
  // form-input
  > input,
  textarea {
    padding: 0.6rem;

    // input place holder make transparent as it mix with label color
    &::placeholder {
      // color: transparent;
      // opacity: 1;
    }
    // from-input on focus change label color
    &:focus,
    &:not(:active) {
      &:not(:placeholder-shown) ~ label {
        color: rgba($primary, 1) !important;
        transition: all 0.25s ease-in-out;
        opacity: 1;
      }
    }
    &:not(:focus) {
      &:not(:placeholder-shown) ~ label {
        color: rgba($black, 0.4) !important;
      }
    }
    // form-label after entering text in input box
    &:not(:placeholder-shown) ~ label {
      padding: 0.25rem 0;
      font-size: 0.7rem;
      top: -20px;
      left: 3px;
      z-index: 1;
    }
  }

  // has-icon left with input
  &.has-icon-left {
    > label {
      left: 36px;
    }
  }
}

/* Vuexy Custom Checkbox */
.vx-checkbox-con {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.25rem 0;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 200;
    cursor: pointer;
    top: 0;
    &:checked {
      ~ .vx-checkbox {
        transform: rotate(0deg);
        border-color: $primary;
        .vx-checkbox--check {
          transform: translate(0);
          background-color: $primary;
          .vx-icon {
            transform: translate(0);
            line-height: 1.2;
            opacity: 1;
          }
        }
        &.vx-checkbox-sm {
          .vx-checkbox--check .vx-icon {
            font-size: 0.75rem;
          }
        }
        &.vx-checkbox-lg {
          .vx-checkbox--check .vx-icon {
            font-size: 1.2rem;
          }
        }
      }
    }
    &:active:checked + .vx-checkbox {
      .vx-checkbox--check {
        transform: translate(3px);
        background-color: $primary;
        .vx-icon {
          opacity: 1;
          transform: translate(6px);
        }
      }
    }
    &:disabled {
      cursor: default;
      pointer-events: none;
      + .vx-checkbox {
        cursor: default;
        opacity: 0.5;
      }
    }
  }
  .vx-checkbox {
    cursor: pointer;
    position: relative;
    width: $vs-checkbox-box;
    height: $vs-checkbox-box;
    border-width: $vs-checkbox-radio-border-width;
    border-style: solid;
    border-color: $vs-checkbox-radio-border-color;
    border-radius: 2px;
    transform: rotate(-90deg);
    transition: all 0.2s ease;
    overflow: hidden;
    margin-right: 0.5rem;
    &.vx-checkbox-sm {
      width: $vs-checkbox-box-sm;
      height: $vs-checkbox-box-sm;
    }
    &.vx-checkbox-lg {
      width: $vs-checkbox-box-lg;
      height: $vs-checkbox-box-lg;
    }
    .vx-checkbox--check {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      left: 0;
      transform: translate(100%);
      transform-origin: right;
      transition: all 0.2s ease;
      z-index: 10;
      .vx-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
      }
    }
  }
}

/* Vuexy Custom Radio */
.vx-radio-con {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.25rem 0;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 200;
    cursor: pointer;
    &:checked {
      ~ .vx-radio {
        transform: rotate(0deg);
        outline: 0;
        .vx-radio--circle {
          background: rgba($primary, 1);
          box-shadow: 0 3px 12px 0 rgba($primary, 0.4);
          opacity: 1;
          transform: scale(1);
        }
        .vx-radio--border {
          opacity: 0;
          transform: scale(0.3);
        }
      }
    }
    &:disabled {
      cursor: default;
      pointer-events: none;
      + .vx-radio {
        cursor: default;
        opacity: 0.5;
      }
    }
    &:active {
      ~ .vx-radio {
        transform: scale(1.1);
      }
    }
  }
  .vx-radio {
    cursor: pointer;
    position: relative;
    width: $vs-radio-box;
    height: $vs-radio-box;
    transition: all 0.2s ease;
    margin-right: 0.5rem;
    &.vx-radio-sm {
      width: $vs-radio-box-sm;
      height: $vs-radio-box-sm;
    }
    &.vx-radio-lg {
      width: $vs-radio-box-lg;
      height: $vs-radio-box-lg;
    }
    .vx-radio--border,
    .vx-radio--circle {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      transition: all 0.25s ease;
      top: 0;
      border-radius: 50%;
    }
    .vx-radio--border {
      background: transparent;
      border: $vs-checkbox-radio-border-width solid
        $vs-checkbox-radio-border-color;
    }
    .vx-radio--circle {
      opacity: 0;
      transform: scale(0.1);
    }
  }
}

/* Switches */

.custom-switch {
  padding-left: 0;

  .custom-control-label {
    height: 1.571rem;
    width: 3.1rem;
    padding: 0;

    /* For bg color of switch*/
    &::before {
      border: none;
      background-color: $switch-bg-color;
      height: 1.571rem;
      left: -0.2857rem;
      box-shadow: none !important;
      transition: all 0.25s ease;
      cursor: pointer;
      user-select: none;
      top: 0;
      left: 0;
    }
    /*For Switch handle*/
    &:after {
      position: absolute;
      top: 2px;
      left: 2px;
      box-shadow: none;
      background-color: $switch-indicator-color;
      transition: all 0.25s ease;
      cursor: pointer;
      user-select: none;
    }

    /*For Switch text*/
    .switch-text-left,
    .switch-text-right,
    .switch-icon-left,
    .switch-icon-right {
      position: absolute;
      top: 5px;
      font-size: 0.7rem;
      cursor: pointer;
      user-select: none;
    }
    .switch-text-left,
    .switch-icon-left {
      left: 7px;
      color: $white;
    }
    .switch-text-right,
    .switch-icon-right {
      right: 7px;
    }
    &:focus {
      outline: 0;
    }
  }

  /*For Switch label*/

  .switch-label {
    padding-left: 1rem;
  }

  // after its checked

  .custom-control-input:checked ~ .custom-control-label::before {
    box-shadow: none;
    background-color: $primary;
  }

  /*For Switch Handle Animation*/
  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.4rem);
  }
  .custom-control-input:not(:checked) ~ .custom-control-label {
    .switch-text-left {
      opacity: 0;
    }
    .switch-text-right {
      opacity: 1;
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    .switch-text-right {
      opacity: 0;
    }
    .switch-text-left {
      opacity: 1;
    }
  }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: $switch-bg-color;
  }
  // For Switch Sizes

  // switch md
  &.switch-md {
    .custom-control-label {
      width: $switch-md-size;
      height: 1.571rem;
      &::before {
        width: $switch-md-size;
        border: none;
        background-color: $switch-bg-color;
        height: 1.571rem;
        left: -0.2857rem;
        box-shadow: none !important;
        transition: all 0.25s ease;
        cursor: pointer;
        user-select: none;
      }
      &:after {
        box-shadow: none;
        background-color: $switch-indicator-color;
        transition: all 0.25s ease;
        cursor: pointer;
        user-select: none;
        position: absolute;
        top: 2px;
        left: -2px;
      }
      .switch-text-right,
      .switch-icon-right {
        right: 9px;
      }
      .switch-text-left,
      .switch-icon-left {
        left: 5px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      transform: translateX(2.3rem);
    }
  }

  // switch lg
  &.switch-lg {
    .custom-control-label {
      width: $switch-lg-size;
      height: 1.571rem;
      &::before {
        width: $switch-lg-size;
        border: none;
        background-color: $switch-bg-color;
        height: 1.571rem;
        left: -0.2857rem;
        box-shadow: none !important;
        transition: all 0.25s ease;
        cursor: pointer;
        user-select: none;
      }
      &:after {
        position: absolute;
        top: 2px;
        left: -2px;
        box-shadow: none;
        background-color: $switch-indicator-color;
        transition: all 0.25s ease;
        cursor: pointer;
        user-select: none;
      }
      .switch-text-right,
      .switch-icon-right {
        right: 10px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      transform: translateX(3.8rem);
    }
  }
}

/* Textarea with Counter */

.counter-value {
  background-color: $primary;
  color: $white;
  padding: 1px 6px;
  font-size: 0.6rem;
  border-radius: 0 0 5px 5px;
  margin-right: 1rem;
}

/* Input box with icon divider */

.form-group {
  &.input-divider-left {
    .form-control-position {
      svg {
        padding: 0.2rem 0.5rem;
        border-right: 1px solid darken($body-bg, 12%);
        height: 25px;
        width: 30px;
      }
    }
  }
  &.input-divider-right {
    .form-control-position {
      svg {
        padding: 0.2rem 0.5rem;
        border-left: 1px solid darken($body-bg, 12%);
        height: 25px;
        width: 30px;
      }
    }
  }
}

/* input-groups-lg and input-groups-sm  */

.input-group-lg,
.input-group-sm {
  .form-control {
    padding: 0.7rem;
  }
}

/***** React Numeric Input *****/
// .react-numeric-input {
//   input {
//     background-color: $body-bg;
//     padding: 0 !important;
//     border: none !important;
//     // width: 100px;
//     height: 26px;
//     font-size: 1rem !important;
//     font-weight: 400;
//     &:focus {
//       outline: 0;
//     }
//   }
//   b {
//     width: 22px;
//     height: 22px;
//     border-radius: 5px !important;
//     bottom: auto !important;
//     background-color: $primary !important;
//     cursor: pointer !important;
//     i {
//       background: $white !important;
//     }
//   }
// }

/* Number Type Input Box Scss for - Remove arrow on hover */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  // appearance: none;
  // margin: 0;
}

// Date & Time Picker - Form Control Bg color
.picker__input {
  &.form-control {
    background-color: $white;
  }
}

// input Groups specific SCSS
.input-group {
  .form-control {
    height: auto;
  }
  .input-group-prepend,
  .input-group-append {
    // dropdown arrow position
    .dropdown-toggle {
      &::after {
        left: 0;
      }
    }
    // checkbox and radio margin
    .vx-checkbox-con,
    .vx-checkbox,
    .vx-radio-con,
    .vx-radio {
      margin: 0;
    }
  }
  &.input-group-lg {
    .form-control {
      &::placeholder {
        font-size: 1.25rem;
      }
    }
    .input-group-prepend,
    .input-group-append {
      .btn {
        line-height: 1.3;
        font-size: 1.3rem;
      }
    }
  }
  &.input-group-sm {
    .form-control {
      &::placeholder {
        font-size: 0.7rem;
      }
    }
  }
}

// Input Group

.input-group {
  .input-group-append {
    .react-ripples {
      .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  .input-group-prepend {
    .react-ripples {
      .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
